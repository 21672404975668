<template>
  <div class="columns mb-0 upload-column">
    <div class="column is-12 mb-0">
      <h3 class="has-text-weight-bold txt-yellow is-uppercase mb-4">
        {{titleUploader}}
      </h3>

      <div id="upload-container-pdf" class="columns pt-4 is-flex-direction-column field-wrap">
        <div class="column is-12 pt-0 pb-1">
          <ul class="upload-file-list" v-if="uploadedFiles.length > 0">
            <li v-for="(file, index) in uploadedFiles" v-bind:key="file.id">
              <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
              <button class="remove-upload-btn" @click="removeFile(uploadedFiles, index)"><span class="icon-close"></span></button>
            </li>
          </ul>

          <ul class="upload-file-list loading" v-if="files.length > 0">
            <li v-for="(file, index) in files" v-bind:key="index">
              <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
              <span class="progress-label">{{parseInt(file.progress)}}%</span>
              <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
            </li>
          </ul>
          <file-upload
            input-id="fileStd"
            class="button is-primary is-outline upload-btn"
            :headers="headers"
            :post-action="uploadPostAction"
            :multiple="true"
            :extensions="fileSupported"
            :size="1024 * 1024 * 10"
            v-model="files"
            name="uploaded_file"
            :thread="1"
            @input-filter="inputFilter"
            @input-file="inputFile"
            :data="{ upload_type: type }"
            ref="uploader">
            SUBIR EL ARCHIVIO
          </file-upload>
        </div>
        <div class="column is-12 pt-2 pl-7">
          <label class="small-txt mobile-block">Format: {{fileSupported}} (max 10MB)</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HumanFileSize,
  IsEmptyOrNull
} from '@/utils/utils'
export default {
  name: 'FileUploadStlab',
  props: {
    uploadedFiles: {
      type: Array
    },
    titleUploader: {
      type: String,
      default: 'Archivio'
    },
    type: {
      type: String
    },
    fileSupported: {
      type: String
    },
    destinationId: {
      type: String
    },
    isLaboratory: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadPostAction: 'https://api-staging.stlab.es/api/v1/uploads',
      files: [],
      headers: null
    }
  },
  methods: {
    removeFile (fileArray, index) {
      fileArray.splice(index, 1)
    },
    convertSize (size) {
      return HumanFileSize(size)
    },
    initUploader () {
      if (this.isLaboratory) {
        this.uploadPostAction = process.env.VUE_APP_BASE_URL + 'order_items/' + this.destinationId + '/uploads'
      } else {
        this.uploadPostAction = process.env.VUE_APP_BASE_URL + 'clinics/' + this.destinationId + '/uploads'
      }
      const accessToken = localStorage.getItem('token')
      this.headers = {
        Authorization: `Bearer ${accessToken}`
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)
      }
      if (newFile && oldFile) {
        // update --> check errors
        console.log('update', newFile)
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (!IsEmptyOrNull(newFile.error)) {
          if (newFile.error === 'extension') {
            for (let i = 0; i < this.files.length; i++) {
              if (newFile.id === this.files[i].id) {
                this.files.splice(i, 1)
                break
              }
            }
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: 'File extension not supported'
              }
            })
          }
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!IsEmptyOrNull(newFile)) {
          if (!this.$refs.uploader.active) {
            this.$refs.uploader.active = true
          }
        }
      }

      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        if (newFile.xhr) {
          //  Get the response status code
          if (newFile.xhr.status !== 200) {
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: newFile.error
              }
            })
            this.$refs.uploader.remove(newFile)
          } else {
            console.log('FINISHED!')
            console.log(newFile.data.upload_type)
            this.uploadedFiles.push(newFile.response)
            this.$refs.uploader.remove(newFile)
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'success',
                message: 'File uploaded successfully!'
              }
            })
          }
        }
      }
    }
  },
  created () {
    this.initUploader()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.upload-column{
  padding-top: 7px;
  padding-bottom: 7px;
  &:first-child{
    padding-right: 14px;
  }
  &:last-child{
    padding-right: 14px;
  }
  &.middle-column{
    padding: 7px 14px;
    border-left: 1px solid rgba(87, 86, 124, .3);
    border-right: 1px solid rgba(87, 86, 124, .3);
  }

  .upload-file-list{
    width: 100%;
    li{
      position: relative;
      padding: 14px;
      background-color: #F6F4EF;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 7px;
      p{
        display: block;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .remove-upload-btn{
        position: absolute;
        top: 0px;
        right: 0px;
        width: calc(10% + 10px);
        height: 100%;
        background-color: transparent;
        border: 0px;
        color: #57567C;
        cursor: pointer;
      }
      .progress-label{
        position: absolute;
        top: 0px;
        right: 0px;
        width: calc(10% + 10px);
        height: 100%;
        background-color: transparent;
        border: 0px;
        color: #57567C;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .loading-bar{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0px;
        height: 2px;
        background-color: #57567C;
        transition: width .3s ease-out;
      }
    }
  }

  .upload-btn{
    margin-top: 14px;
    width: 100%;
  }
}
</style>
