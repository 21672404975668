<template>
  <div class="communication-center-wrap">

    <div id="panel-scrollable" class="panel-scrollable">
      <ul class="msg-list">
        <li v-for="message in messages" :key="message.id">
          <div class="msg-bubble" v-if="message.text !== ''"
            :class="{ 'msg-right': message.user.id === chatMember.id }"
          >
            <div class="msg-img" v-if="message.attachment && !message.attachment.includes('.pdf')">
              <img :src="message.attachment">
            </div>
            <div class="msg-file" v-if="message.attachment && message.attachment.includes('.pdf')">
              <a :href="message.attachment"><span class="icon-download download-icon"></span> <span class="download-label">Download attachment</span></a>
            </div>
            <p>{{ message.text }}</p>
          </div>
        </li>
      </ul>
    </div>

    <form class="panel-submit" @submit.prevent="sendMessage" method="post">
      <div class="preview-upload-wrap" v-if="uploadedFile !== null || uploadedImage !== null">
        <div class="preview image" v-if="uploadedImage !== null">
          <img :src="uploadedImage.url">
          <button class="remove-attachment-btn" @click="removeImage()">
            <span class="icon-remove"></span>
          </button>
        </div>
        <div class="preview file" v-if="uploadedFile !== null">
          <button class="remove-attachment-btn" @click="removeFile()">
            <span class="icon-remove"></span>
          </button>
          <p>{{uploadedFile.name}} <small>({{formatFileSize(uploadedFile.size)}})</small></p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-10">
          <input-text :placeholder="'Start chatting...'" v-model="newMessage" :name="'newMessage'"></input-text>
        </div>
        <div class="column is-2">
          <button class="button is-primary is-outlined">Send</button>
          <label class="chat-img-upload-btn" for="chat-img-upload">
            <span class="icon-download"></span>
          </label>
          <input id="chat-img-upload" type="file" @change="getUrl($event)"/>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import InputText from '@/components/FormElements/InputText.vue'
import { StreamChat } from 'stream-chat'
import { IsEmptyOrNull } from '@/utils/utils'

export default {
  name: 'CommunicationCenter',
  components: {
    InputText
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chatChannel: {
        id: null
      },
      chatMember: {
        id: null
      },
      clientChat: null,
      channel: null,
      messages: null,
      newMessage: '',
      uploadedFile: null,
      uploadedImage: null
    }
  },
  methods: {
    formatFileSize (bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log(bytes) / Math.log(1024))
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`
    },
    async removeImage () {
      // Delete Image
      await this.channel.deleteImage(this.uploadedImage.url)
      this.uploadedImage = null
    },
    async removeFile () {
      // Delete File
      await this.channel.deleteFile(uploadedFile.url)
      this.uploadedFile = null
    },
    async getUrl (event) {
      const files = event.target.files
      if (files.item(0).type.includes('image')) {
        const response = await this.channel.sendImage(files[0])
        this.uploadedImage = files.item(0)
        this.uploadedImage.url = response.file
      } else {
        const response = await this.channel.sendFile(files[0])
        this.uploadedFile = files.item(0)
        this.uploadedFile.url = response.file
      }

      document.getElementById('chat-img-upload').value = ''
      /* this.$store.commit({
        type: 'application/ADD_FEEDBACK',
        feedback: {
          type: 'alert',
          message: 'File not supported'
        }
      })
      console.log(event)
      console.log('Selected file: ' + files.item(0).name)
      console.log('Selected file: ' + files.item(0).size)
      console.log('Selected file: ' + files.item(0).type) */
    },
    async sendMessage () {
      const payload = {
        text: this.newMessage
      }

      if (!IsEmptyOrNull(this.uploadedImage)) {
        payload.attachment = this.uploadedImage.url
      }

      if (!IsEmptyOrNull(this.uploadedFile)) {
        payload.attachment = this.uploadedFile.url
      }
      await this.channel.sendMessage(payload)

      this.uploadedImage = null
      this.uploadedFile = null
      this.newMessage = ''
    },
    async getOrderChatSession () {
      const self = this
      await this.$store.dispatch('clinic/getOrderChatSession', {
        orderId: this.orderId,
        cb: async (data) => {
          console.log('getOrderChatSession')
          self.chatChannel = data.channel
          self.chatMember = data.member
          await this.initializeStream()
          await this.initializeChannel()
        },
        cbError: () => {
          const errorMessage = '¡Error! Can\'t init chat'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: errorMessage
            }
          })
        }
      })
    },
    async initializeStream () {
      console.log('initializeStream')
      this.clientChat = new StreamChat(process.env.VUE_APP_STREAM_API_KEY)
      // await this.clientChat.setUser({ id: this.chatMember.id, name: this.name }, this.chatMember.token)
      await this.clientChat.connectUser({ id: this.chatMember.id, name: this.name }, this.chatMember.token).catch(error => {
        console.log(error)
        const errorMessage = '¡Error! Can\'t connect user to the chat'
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: errorMessage
          }
        })
      })
    },
    async initializeChannel () {
      console.log('initializeChannel')
      this.channel = this.clientChat.channel(this.chatChannel.type, this.chatChannel.id)
      this.messages = (await this.channel.watch()).messages
      this.scrollToBottom()

      this.channel.on('message.new', event => {
        if (event.message.text !== '') {
          this.messages.push({
            attachment: event.message.attachment,
            text: event.message.text,
            user: event.message.user
          })
          this.scrollToBottom()
        }
      })
    },
    async init () {
      await this.getOrderChatSession()
    },
    scrollToBottom () {
      const self = this
      setTimeout(() => {
        self.$nextTick(function () {
          const container = document.getElementById('panel-scrollable')
          container.scroll({
            top: container.scrollHeight,
            behavior: 'smooth'
          })
        })
      }, 800)
    }
  },
  created () {
    this.init()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.communication-center-wrap{
  // background-color: lightblue;
  padding: 14px;
  height: 500px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  .panel-scrollable{
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
    .msg-list{
      width: 100%;
      li{
        width: 100%;
        .msg-bubble{
          position: relative;
          left: 0px;
          width: 90%;
          padding: 21px;
          background-color: #EAF3E8;
          border-radius: 8px;
          margin-bottom: 14px;
          margin-left: 20px;
          &:before{
            content: '';
            position: absolute;
            left: -20px;
            top: 50%;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 20px 10px 0;
            border-color: transparent #EAF3E8 transparent transparent;
            transform: rotate(0deg) translate(0, -50%);
          }
          .msg-img, .msg-file{
            margin-bottom: 14px;
            border-radius: 8px;
            overflow: hidden;
            max-width: 50%;
            img{
              width: 100%;
            }
          }
          .msg-file{
            background-color: #F6F4EF;
            padding: 14px 7px;
            a{
              display: flex;
              align-items: center;
              .download-label{
                align-self: center;
                text-decoration: underline;
                padding-left: 14px;
              }
              .download-icon{
                font-size: 1.8rem;
              }
            }
          }
          &.msg-right{
            left: initial;
            right: 0px;
            background-color: #F9E3E3;
            margin-left: calc(10% - 20px);
            &:before{
              display: none;
            }
            &:after{
              content: '';
              position: absolute;
              left: initial;
              right: -20px;
              top: 50%;
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 10px 20px 10px 0;
              border-color: transparent #F9E3E3 transparent transparent;
              transform: rotate(180deg) translate(0, 50%);
            }
          }
        }
      }
    }
  }
  .panel-submit{
    position: relative;
    margin-top: 20px;
    height: 50px;
    width: 100%;
    .chat-img-upload-btn {
      cursor: pointer;
      display: inline-block;
      height: 46px;
      width: 46px;
      border: 2px solid #57567C;
      text-align: center;
      line-height: 46px;
      border-radius: 4px;
      margin-left: 14px;
      transform: rotate(180deg);
      span{
        position: relative;
        color: #57567C;
        font-size: 2rem;
        top: 7px;
      }
    }
    #chat-img-upload {
      display: none;
    }
    .preview-upload-wrap{
      position: absolute;
      bottom: calc(100% + 14px);
      left: 0px;
      max-width: 50%;
      .preview {
        position: relative;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        &.image{
          img{
            max-height: 200px;
          }
        }
        &.file{
          background-color: #F6F4EF;
          padding: 14px 7px;
          .remove-attachment-btn{
            top: -24px;
            right: -24px;
          }
        }
        .remove-attachment-btn{
          position: absolute;
          top: -10px;
          right: -10px;
          height: 40px;
          width: 40px;
          background-color: #E37878;
          color: #FFF;
          border-radius: 50%;
          border: 0px;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
