import { render, staticRenderFns } from "./FileUploadStlab.vue?vue&type=template&id=69945765&scoped=true&"
import script from "./FileUploadStlab.vue?vue&type=script&lang=js&"
export * from "./FileUploadStlab.vue?vue&type=script&lang=js&"
import style0 from "./FileUploadStlab.vue?vue&type=style&index=0&id=69945765&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69945765",
  null
  
)

export default component.exports